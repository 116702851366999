/* eslint-disable @typescript-eslint/prefer-regexp-exec */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  ATMButton,
  ATMCheckbox,
  ATMField,
  ATMInput,
  IORGDataTableColumn,
  ORGDataTable,
  formatTime,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import { debounce, orderBy } from 'lodash';
import Lang from 'src/libraries/language';
import { createDateTime, format24hTime } from 'src/libraries/moment.library';
import { ILerRequestForm } from 'src/models/ler-request.model';
import { ISortResponse } from 'src/models/switching.model';
import {
  HourTimePattern,
  restrictAlphabetsAndSpecialChars,
} from 'src/components/atoms/input/time-input-switching-format.component';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import moment from 'moment';
import styles from './ler-request-switching-form.module.scss';
import LERRequestSwitchingETS from '../ler-request-switching-ets/ler-request-switching-ets.component';
import LERSwitchingDateInput from './ler-request-switching-form-date.component';
import history from '../../../../../history';

export enum CheckType {
  Send = 'sent',
  Cancel = 'cancellation',
}

export enum SortStatus {
  COMPLETE = 'COMPLETE',
  COMPLETED = 'COMPLETED',
}

export type SortTimeUpdate = {
  record: ISortResponse;
  sent: boolean;
  cancellation: boolean;
  index: number;
  data?: ISortResponse;
};

export const isSortStatusComplete = (status: string) => {
  if (status) {
    return (
      status.toUpperCase() === SortStatus.COMPLETE ||
      status.toUpperCase() === SortStatus.COMPLETED
    );
  }
  return false;
};

const LERRequestSwitchingForm: React.FC<{
  hasUpdate?: boolean;
  loading?: boolean;
  data?: ISortResponse[] | undefined;
  isRealTimeOperator?: boolean;
}> = ({
  hasUpdate = false,
  loading = false,
  isRealTimeOperator = false,
  data,
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    clearErrors,
    setValue,
    setError,
  } = useATMFormContext<ILerRequestForm>();
  const { state } = useLerRequestContext();
  const [isSendAll, setIsSendAll] = useState<boolean | undefined>(false);
  const [isCancelAll, setIsCancelAll] = useState<boolean | undefined>(false);
  const emptyTimeFields = useRef<any[]>([]);
  const [showComplete, setShowComplete] = useState(false);
  const { fields, update } = useFieldArray({
    control,
    name: 'sortResponses',
  });

  const status = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_DATA_READ
  );

  useEffect(() => {
    if (fields.length) {
      const filteredList = fields?.filter(
        (val) => !isSortStatusComplete(val?.sortStat as string)
      );
      if (filteredList.length) {
        const sent = filteredList.every((v) => v.sent);
        const cancellation = filteredList.every((v) => v.cancellation);
        setIsSendAll(sent);
        setIsCancelAll(cancellation);
      }
    }
  }, [fields]);

  const handleSortTimeUpdate = useCallback(
    (sortData: SortTimeUpdate) => {
      update(sortData.index, {
        ...sortData.record,
        sent: sortData.sent,
        cancellation: sortData.cancellation,
      });
    },
    [update]
  );

  const setSortResponses = useCallback(() => {
    if (isRealTimeOperator) {
      setValue(
        'sortResponses',
        orderBy(data, ['sortTime', 'outBack'], ['asc', 'desc'])
      );
    }
  }, [setValue, data, isRealTimeOperator]);

  useEffect(() => {
    setSortResponses();
  }, [data]);

  useEffect(() => {
    clearErrors('sortResponses');
    emptyTimeFields.current = [];
  }, [showComplete]);

  const getIndex = useCallback(
    (currentIndex: number, workOrderNo: any): number => {
      let index = currentIndex;
      if (!showComplete) {
        fields.map((val, i) => {
          if (workOrderNo === val.sortId) {
            index = i;
          }
        });
      }
      return index;
    },
    [fields, showComplete]
  );

  const handleCheckAll = useCallback(
    (
      type: CheckType,
      checked?: boolean,
      showAll?: boolean,
      active?: boolean
    ) => {
      if (showAll) {
        fields.map((val, i) => {
          if (isSortStatusComplete(val?.sortStat as string)) {
            handleSortTimeUpdate({
              cancellation: false,
              sent: false,
              index: i,
              record: val,
            });
          } else {
            handleSortTimeUpdate({
              cancellation: val.cancellation as boolean,
              sent: val.sent as boolean,
              index: i,
              record: {
                ...val,
                date: val.sortTime,
                time: format24hTime(val.sortTime),
              },
            });
          }
        });
      } else if (active) {
        fields.forEach((value, i) => {
          if (!isSortStatusComplete(value?.sortStat as string)) {
            if (
              emptyTimeFields.current.length > 0 &&
              emptyTimeFields.current.includes(i)
            ) {
              handleSortTimeUpdate({
                cancellation: value.cancellation as boolean,
                sent: false,
                index: i,
                record: {
                  ...value,
                  date: value.sortTime,
                  time: format24hTime(value.sortTime),
                },
              });
            } else {
              handleSortTimeUpdate({
                cancellation: value.cancellation as boolean,
                sent: value.sent as boolean,
                index: i,
                record: {
                  ...value,
                  date: value.sortTime,
                  time: format24hTime(value.sortTime),
                },
              });
            }
          }
        });
      } else {
        fields.forEach((value, i) => {
          if (!isSortStatusComplete(value?.sortStat as string)) {
            if (
              emptyTimeFields.current.length > 0 &&
              emptyTimeFields.current.includes(i)
            ) {
              handleSortTimeUpdate({
                cancellation: (type === CheckType.Cancel && checked) ?? false,
                sent: false,
                index: i,
                record: value,
              });
            } else {
              handleSortTimeUpdate({
                cancellation: (type === CheckType.Cancel && checked) ?? false,
                sent: (type === CheckType.Send && checked) ?? false,
                index: i,
                record: value,
              });
            }
          }
        });
      }
    },
    [fields, handleSortTimeUpdate, emptyTimeFields]
  );

  const handleUpdate = useCallback(
    debounce((index, values) => {
      update(index, {
        ...values,
      });
    }, 300),
    [update]
  );

  const handleEmptyValue = useCallback(
    (
      indexValue: any,
      dataValue: any,
      record: ISortResponse,
      isDate = false
    ) => {
      if (!dataValue) {
        if (!isDate) {
          clearErrors(
            `sortResponses[${getIndex(indexValue, record.sortId)}].time` as any
          );
        }
        update(indexValue, {
          ...record,
          date: isDate ? undefined : record.date,
          time: !isDate ? undefined : record.time,
          cancellation: record.cancellation,
          sent: false,
        });
        emptyTimeFields.current.push(indexValue);
      } else if (dataValue && dataValue.length > 4) {
        if (!dataValue.match(HourTimePattern.HOUR_TIME_PATTERN)) {
          emptyTimeFields.current.push(indexValue);
          update(indexValue, {
            ...record,
            time: dataValue,
          });
        }
      }
      const uniqueItems = Array.from(
        new Set(emptyTimeFields.current.map((a) => a))
      ).map((item) => {
        return emptyTimeFields.current.find((a) => a === item);
      });
      const time = getValues()?.sortResponses?.[indexValue]?.time;
      const date = getValues()?.sortResponses?.[indexValue]?.date;
      if (dataValue) {
        if (
          time &&
          time.length > 0 &&
          time.match(HourTimePattern.HOUR_TIME_PATTERN as any) &&
          date
        ) {
          const valArrs: any[] = [];
          uniqueItems.map((val) => {
            if (val !== indexValue) {
              valArrs.push(val);
            }
          });
          emptyTimeFields.current = valArrs;
        }
      } else {
        emptyTimeFields.current = (uniqueItems as any) ?? [];
      }
    },
    [emptyTimeFields, handleSortTimeUpdate, update, getValues, clearErrors]
  );

  const handleEmptyValueDebounce = useCallback(
    debounce((indexValue: any, dataValue: any, record: ISortResponse) => {
      handleEmptyValue(indexValue, dataValue, record);
    }, 50),
    [handleEmptyValue]
  );

  const handleSwitching = useCallback(
    (showAll: boolean) => {
      if (showAll) {
        setShowComplete(showAll);
        handleCheckAll('' as any, false, true, false);
      } else {
        setShowComplete(false);
        handleCheckAll('' as any, false, false, true);
        if (isCancelAll || isSendAll) {
          const checkType = isCancelAll ? CheckType.Cancel : CheckType.Send;
          handleCheckAll(checkType, isCancelAll || isSendAll, false, true);
        }
      }
    },
    [setShowComplete, handleCheckAll, isCancelAll, isSendAll]
  );

  const checkSortStatus = useCallback(() => {
    const sortStatValues = Object.values(SortStatus);
    const withCompleteStatus = fields.filter((v) =>
      sortStatValues
        ?.toString()
        .toUpperCase()
        .includes(v.sortStat?.toUpperCase() as unknown as SortStatus)
    );
    return withCompleteStatus.length > 0;
  }, [fields]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleTimeUpdate = useCallback(
    debounce((value, name) => {
      const input = document.getElementsByName(name)[0] as HTMLInputElement;
      const nativeInputValueSetter = Object?.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      )?.set;
      nativeInputValueSetter?.call(input, value);
      const ocEvent = new Event('input', { bubbles: true });
      input?.dispatchEvent(ocEvent);

      if (input && value) {
        input.setSelectionRange(value.length, value.length);
      }
      input?.focus();
    }, 50),
    []
  );

  const columns: IORGDataTableColumn<ISortResponse>[] = [
    {
      title: Lang.LBL_DATE,
      index: 'date',
      render: (_value, values, index) => (
        <>
          <div className={styles.fieldTimePicker}>
            <LERSwitchingDateInput
              control={control}
              disabled={isSortStatusComplete(values?.sortStat as string)}
              name={`sortResponses[${getIndex(index, values.sortId)}].date`}
              update={update}
              getValues={getValues}
              setError={setError}
              clearErrors={clearErrors}
              index={getIndex(index, values.sortId)}
              handleEmptyValue={handleEmptyValue}
              defaultValue={
                getValues()?.sortResponses?.[getIndex(index, values.sortId)]
              }
            />
          </div>
        </>
      ),
    },
    {
      title: Lang.LBL_TIME,
      index: 'time',
      render: (_value, values, index) => (
        <>
          <div className={styles.timeWrapper}>
            <span>{`${values.outBack} ${values.byAt}`}</span>
            <div>
              <ATMInput
                ref={inputRef}
                name={`sortResponses[${getIndex(index, values.sortId)}].time`}
                control={control}
                maxLength={5}
                placeholder="hh:mm"
                value={format24hTime(
                  getValues()?.sortResponses?.[getIndex(index, values.sortId)]
                    ?.sortTime
                )}
                onChange={(_, { value }) => {
                  const timeValue =
                    value && value.length > 3
                      ? formatTime(restrictAlphabetsAndSpecialChars(value))
                      : restrictAlphabetsAndSpecialChars(value);
                  if (timeValue) {
                    if (timeValue.length > 4) {
                      handleTimeUpdate(
                        timeValue,
                        `sortResponses[${getIndex(index, values.sortId)}].time`
                      );
                      if (
                        timeValue.match(
                          HourTimePattern.HOUR_TIME_PATTERN as any
                        )
                      ) {
                        clearErrors(
                          `sortResponses[${getIndex(
                            index,
                            values.sortId
                          )}].time` as any
                        );
                        update(getIndex(index, values.sortId), {
                          ...values,
                          time: timeValue,
                          sortTime: createDateTime(
                            moment(values.sortTime as unknown as any).toDate(),
                            timeValue
                          ).toDate(),
                        });
                        handleEmptyValue(
                          getIndex(index, values.sortId),
                          timeValue,
                          values as any
                        );
                      } else {
                        update(getIndex(index, values.sortId), {
                          ...values,
                          time: timeValue,
                        });
                        handleEmptyValue(
                          getIndex(index, values.sortId),
                          timeValue,
                          values as any
                        );
                        setError(
                          `sortResponses[${getIndex(
                            index,
                            values.sortId
                          )}].time` as any,
                          {
                            message: 'Invalid Time',
                          }
                        );
                      }
                    }
                  }
                  handleEmptyValueDebounce(
                    getIndex(index, values.sortId),
                    timeValue,
                    values as any
                  );
                  return timeValue;
                }}
                clearable
                disabled={isSortStatusComplete(values.sortStat as string)}
              />
            </div>
          </div>
          <span>
            <ATMField
              control={control}
              as={ATMInput}
              name={`sortResponses[${getIndex(index, values.sortId)}].time`}
              defaultValue=""
              type="hidden"
              error={
                errors?.sortResponses?.[getIndex(index, values.sortId)]?.time
              }
              className={styles.timeError}
            />
          </span>
        </>
      ),
    },
    {
      title: Lang.LBL_TL_SUB,
      index: 'tlSub',
    },
    {
      title: Lang.LBL_SUB_POLE,
      index: 'subPole',
    },
    {
      title: Lang.LBL_AREA,
      index: 'subDistId',
    },
    {
      title: Lang.LBL_REMARKS,
      index: 'dspchRem',
      render: (_value, values, index) => (
        <ATMField
          as={ATMInput}
          name={`sortResponses[${getIndex(index, values.sortId)}].dspchRem`}
          value={
            getValues()?.sortResponses?.[getIndex(index, values.sortId)]
              ?.dspchRem ?? ''
          }
          onChange={(_, { value }) => {
            handleUpdate(getIndex(index, values.sortId), {
              ...values,
              dspchRem: value,
            });

            return value;
          }}
          clearable
          error={
            errors?.sortResponses?.[getIndex(index, values.sortId)]?.dspchRem
          }
          disabled={isSortStatusComplete(values.sortStat as string)}
        />
      ),
    },
    {
      title: Lang.LBL_ORDER_NUM,
      index: 'sortId',
    },
    {
      title: Lang.LBL_STATUS,
      index: 'sortStat',
      render: (_, values, _index) => values?.sortStat?.toUpperCase(),
    },
    {
      title: (
        <>
          Send
          <ATMCheckbox
            checked={isSendAll}
            onChange={(_, { checked }) => {
              setIsSendAll(checked);
              handleCheckAll(CheckType.Send, checked);

              return checked;
            }}
          />
        </>
      ),
      index: 'sent',
      render: (value, values, index) => (
        <ATMField
          key={`sortResponses_sent_${getIndex(index, values.sortId)}_${
            value ? '1' : '0'
          }`}
          as={ATMCheckbox}
          disabled={
            emptyTimeFields.current.includes(getIndex(index, values.sortId)) ||
            isSortStatusComplete(values.sortStat as string)
          }
          name={`sortResponses[${getIndex(index, values.sortId)}].sent`}
          className={
            emptyTimeFields.current.includes(getIndex(index, values.sortId)) ||
            isSortStatusComplete(values.sortStat as string)
              ? styles.disableCenterCheckbox
              : ''
          }
          style={{ marginBottom: '0' }}
          readOnly={isSortStatusComplete(values.sortStat as string)}
          control={control}
          onChange={([_, { checked }]) => {
            update(getIndex(index, values.sortId), {
              ...values,
              cancellation:
                checked && values.cancellation === true
                  ? false
                  : values.cancellation || false,
              sent: checked,
            });

            return checked;
          }}
        />
      ),
    },
    {
      title: (
        <>
          Cancel
          <ATMCheckbox
            checked={isCancelAll}
            onChange={(_, { checked }) => {
              setIsCancelAll(checked);
              handleCheckAll(CheckType.Cancel, checked);

              return checked;
            }}
          />
        </>
      ),
      index: 'cancellation',
      render: (value, values, index) => (
        <ATMField
          key={`sortResponses_cancel_${getIndex(index, values.sortId)}_${
            value ? '1' : '0'
          }`}
          as={ATMCheckbox}
          disabled={
            // emptyTimeFields.current.includes(getIndex(index, values.sortId)) ||
            isSortStatusComplete(values.sortStat as string)
          }
          className={
            // emptyTimeFields.current.includes(getIndex(index, values.sortId)) ||
            isSortStatusComplete(values.sortStat as string)
              ? styles.disableCenterCheckbox
              : ''
          }
          name={`sortResponses[${getIndex(index, values.sortId)}].cancellation`}
          style={{ marginBottom: '0' }}
          readOnly={isSortStatusComplete(values.sortStat as string)}
          control={control}
          onChange={([_, { checked }]) => {
            update(getIndex(index, values.sortId), {
              ...values,
              sent:
                checked && values.sent === true ? false : values.sent || false,
              cancellation: checked,
            });

            return checked;
          }}
        />
      ),
    },
  ];
  return (
    <div className={styles.switchingDivWrapper}>
      <ORGDataTable
        columns={columns}
        loading={status.fetching}
        data={
          showComplete
            ? fields
            : fields?.filter(
                (val) => !isSortStatusComplete(val?.sortStat as string)
              )
        }
        location={history.location}
        handleLocation={history.push}
        rowsPerPageOptions={[20]}
      >
        {() => ({
          toolbars: {
            buttons: () => (
              <>
                {hasUpdate && (
                  <ATMButton
                    primary
                    loading={loading}
                    disabled={loading}
                    style={{ marginRight: '.3em' }}
                  >
                    {Lang.LBL_UPDATE}
                  </ATMButton>
                )}
                <LERRequestSwitchingETS />
                {fields.length && checkSortStatus() ? (
                  <ATMCheckbox
                    label={Lang.LBL_SHOW_COMPLETED}
                    toggle
                    style={{
                      float: 'right',
                      marginLeft: '.3em',
                      marginBottom: '-1em',
                      marginTop: '.5em',
                    }}
                    onChange={(__, { checked }) => {
                      handleSwitching(checked ?? false);
                    }}
                  />
                ) : (
                  ''
                )}
              </>
            ),
          },
        })}
      </ORGDataTable>
    </div>
  );
};

export default LERRequestSwitchingForm;
